<template>
  <VFlex xs3 class="sidebar">
    <template v-if="!loggedIn">
      <div class="sidebar-title mt-4 ml-2">
        <h2>Resto</h2>
        <h3>You must be signed in to edit this layout.</h3>
      </div>
      <BaseButton class="mt-4" @click="showAuthModal">Sign In</BaseButton>
      <div>
        <BaseButton color="purple lighten-2" @click="toggleLayoutEditUI"
          >Full Screen</BaseButton
        >
      </div>
    </template>
    <template v-else>
      <div class="sidebar-title">
        <h3>{{ layout.title }}</h3>
      </div>
      <BaseButtonOutline small outline-color="teal" @click="logOut"
        >Log Out</BaseButtonOutline
      >
      <BaseButton color="teal lighten-2" small to="/">View Site</BaseButton>
      <BaseButton color="purple lighten-2" small @click="toggleLayoutEditUI"
        >Full Screen</BaseButton
      >
      <BaseButton color="blue" small @click="toggleEditUI">{{
        isShowingEditUI ? 'Hide Edit Controls' : 'Show Edit Controls'
      }}</BaseButton>

      <VLayout row wrap>
        <VFlex>
          <VSlider
            v-model="fontSize"
            min="1"
            max="120"
            hide-details
            label="Font Size"
            color="blue darken-2"
          />
        </VFlex>
        <VFlex shrink style="width:60px;" pl-3>
          <VTextField
            v-model="fontSize"
            type="number"
            hide-details
            single-line
            dense
          />
        </VFlex>
      </VLayout>

      <VTabs
        v-model="sidebarIndex"
        grow
        class="mt-1"
        color="transparent"
        slider-color="teal lighten-2"
      >
        <VTab v-for="tab of tabs" :key="tab.slug">{{ tab.title }}</VTab>
      </VTabs>

      <VTabsItems v-model="sidebarIndex" class="mt-2">
        <VTabItem lazy>
          <VTextField
            v-model="searchQuery"
            label="Search"
            hide-details
            clearable
            dense
            append-icon="search"
            class="pt-0 pb-2 px-2"
          />
          <WithRelatedLayoutElements v-slot="{ elements }" :location="location">
            <LayoutElementPicklist is-summary-view :elements="elements" />
          </WithRelatedLayoutElements>
        </VTabItem>
        <VTabItem lazy>
          <LayoutElementPicklist
            :elements="textElements"
            @changed="setDefaultTextElements"
          />
        </VTabItem>
        <VTabItem lazy>
          <LayoutElementPicklist
            :elements="imageElements"
            @changed="setDefaultImageElements"
          />
        </VTabItem>
      </VTabsItems>
    </template>
  </VFlex>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { CACHE_LAYOUT_FIELD } from '@constants/mutations'
import { VTabsItems, VTabItem } from '@vuetify'

import LayoutElementPicklist from '@components/Layout/LayoutElementPicklist'

export default {
  name: 'LayoutEditorSidebar',
  components: {
    LayoutElementPicklist,
    VTabsItems,
    VTabItem,
  },
  props: {
    layout: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fontSizeDebounce: null,
      tabs: [
        { slug: 'related', title: 'Site & Menu' },
        { slug: 'text', title: 'Text' },
        { slug: 'image', title: 'Image' },
      ],
      textElements: [],
      imageElements: [],
      sidebarIndex: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('layoutEditor', ['elementSearch']),
    ...mapGetters('editor', ['isShowingEditUI']),
    fontSize: {
      get() {
        return this.layout.font_size
      },
      set(size) {
        this.changeFontSize(size)
      },
    },
    searchQuery: {
      get() {
        return this.elementSearch
      },
      set(query) {
        this.updateElementSearch(query)
      },
    },
  },
  mounted() {
    this.setDefaultTextElements()
    this.setDefaultImageElements()
  },
  methods: {
    ...mapActions('layouts', ['updateLayout']),
    ...mapActions('layoutEditor', [
      'toggleLayoutEditUI',
      'updateElementSearch',
    ]),
    ...mapActions('editor', ['toggleEditUI']),
    ...mapActions('auth', ['showAuthModal', 'logOut']),
    ...mapMutations('layouts', [CACHE_LAYOUT_FIELD]),

    updateFontSize() {
      this.updateLayout({
        id: this.layout.id,
        font_size: this.layout.font_size,
      })
      this.fontSizeDebounce = null
    },
    changeFontSize(size) {
      this[CACHE_LAYOUT_FIELD]({
        id: this.layout.id,
        field: 'font_size',
        value: size,
      })
      if (this.fontSizeDebounce) {
        return
      }
      this.fontSizeDebounce = setTimeout(this.updateFontSize, 1000)
    },
    setDefaultTextElements() {
      this.textElements = []
      this.$nextTick(() => {
        this.textElements = [
          {
            title: 'text',
            type: 'text',
            content: { text: 'Enter text here...' },
          },
        ]
      })
    },
    setDefaultImageElements() {
      this.imageElements = []
      this.$nextTick(() => {
        this.imageElements = [
          {
            title: 'image',
            type: 'image',
            content: { text: 'Upload image here...' },
          },
        ]
      })
    },
  }, // methods
}
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 340px;
  min-height: 100vh;
  padding: 1em;
  margin-right: 1em;
  background: #eee;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
}
</style>
